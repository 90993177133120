import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogPanel,
  DisclosureButton,
  DisclosurePanel,
  Disclosure
} from '@headlessui/react';
import { XMarkIcon, ChevronDownIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { useBreadcrumb } from '../BreadcrumbContext';
import { useTranslation } from 'react-i18next';
import { IntlProvider, FormattedNumber } from 'react-intl';

export default function RacketList({ rackets }) {
  const { t } = useTranslation();
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilters, setSelectedFilters] = useState(() => {
    const savedFilters = localStorage.getItem('racketFiltersList');
    return savedFilters ? JSON.parse(savedFilters) : {
      price: [],
      brand: [],
      flexibility: [],
      balance: [],
      weight: [],
      gripsize: [],
      material: []
    };
  });
  const [filteredRackets, setFilteredRackets] = useState(rackets);
  const [visibleRackets, setVisibleRackets] = useState([]);
  const [racketsToShow, setRacketsToShow] = useState(9);
  const { setBreadcrumbs } = useBreadcrumb();

  useEffect(() => {
    setBreadcrumbs([
      { name: t('Home'), href: '/' },
      { name: t('Badminton'), href: '/badminton/rackets' },
      { name: t('Rackets'), href: '/badminton/rackets' }
    ]);
  }, [setBreadcrumbs, t]);

  useEffect(() => {
    filterRackets();
  }, [rackets, searchQuery, selectedFilters]);

  useEffect(() => {
    setVisibleRackets(filteredRackets.slice(0, racketsToShow));
  }, [filteredRackets, racketsToShow]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilterChange = (e, filterId) => {
    const value = e.target.value;
    const checked = e.target.checked;
    const newFilters = { ...selectedFilters };

    if (checked) {
      newFilters[filterId].push(value);
    } else {
      newFilters[filterId] = newFilters[filterId].filter(item => item !== value);
    }

    setSelectedFilters(newFilters);
    localStorage.setItem('racketFiltersList', JSON.stringify(newFilters));
  };

  const filterRackets = () => {
    let updatedRackets = rackets;

    if (searchQuery) {
      updatedRackets = updatedRackets.filter(
        (racket) =>
          racket.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          racket.brand.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    Object.keys(selectedFilters).forEach((filterId) => {
      if (selectedFilters[filterId].length > 0) {
        if (filterId === 'weight') {
          updatedRackets = updatedRackets.filter((racket) =>
            racket.weights.some(weight => selectedFilters[filterId].includes(weight))
          );
        } else if (filterId === 'gripsize') {
          updatedRackets = updatedRackets.filter((racket) =>
            racket.grip_sizes.some(grip_size => selectedFilters[filterId].includes(grip_size))
          );
        } else if (filterId === 'material') {
          updatedRackets = updatedRackets.filter((racket) =>
            racket.materials.some(material => selectedFilters[filterId].includes(material))
          );
        } else if (filterId === 'price') {
          updatedRackets = updatedRackets.filter((racket) => {
            const lowestPrice = racket.lowestPrice?.amount || 0;
    
            return selectedFilters[filterId].some((range) => {
              if (lowestPrice === 0) {
                return false;
              }
              if (range.includes('+')) {
                const minPrice = parseFloat(range.split('+')[0]);
                return lowestPrice >= minPrice;
              } else {
                const [minPrice, maxPrice] = range.split('-').map(Number);
                return lowestPrice >= minPrice && lowestPrice <= maxPrice;
              }
            });
          });
        }
        else {
          updatedRackets = updatedRackets.filter((racket) =>
            selectedFilters[filterId].includes(racket[filterId])
          );
        }
      }
    });

    setFilteredRackets(updatedRackets);
  };

  const loadMoreRackets = () => {
    setRacketsToShow((prev) => prev + 9);
  };

  const filters = [];

  let priceFilter = {
    id: 'price',
    name: t('Price'),
    options: [
      { value: '0-25', label: '0 € - 25 €' },
      { value: '25-50', label: '25 € - 50 €' },
      { value: '50-75', label: '50 € - 75 €' },
      { value: '75-100', label: '75 € - 100 €' },
      { value: '100-150', label: '100 € - 150 €' },
      { value: '150+', label: '150 €+' },
    ]
  };

  filters.push(priceFilter);

  let brandFilter = {
    id: 'brand',
    name: t('Brand'),
    options: []
  };

  let brands = [];
  rackets.forEach(racket => {
    if (!brands.includes(racket.brand)) {
      brandFilter.options.push({
        value: racket.brand,
        label: racket.brand
      });
      brands.push(racket.brand);
    }
  });

  filters.push(brandFilter);

  let flexibilityFilter = {
    id: 'flexibility',
    name: t('Flexibility'),
    options: [
      { value: 'FLEXIBLE', label: t('Flexible') },
      { value: 'MEDIUM', label: t('Medium') },
      { value: 'STIFF', label: t('Stiff') }
    ]
  };

  filters.push(flexibilityFilter);

  let balanceFilter = {
    id: 'balance',
    name: t('Balance'),
    options: [
      { value: 'HEAD_HEAVY', label: t('Head heavy') },
      { value: 'BALANCED', label: t('Balanced') },
      { value: 'HEAD_LIGHT', label: t('Head light') }
    ]
  };

  filters.push(balanceFilter);

  let weightFilter = {
    id: 'weight',
    name: t('Weights'),
    options: [
      { value: '6U', label: '6U (70g - 74.9g)' },
      { value: '5U', label: '5U (75g - 79.9g)' },
      { value: '4U', label: '4U (80g - 84.9g)' },
      { value: '3U', label: '3U (85g - 89.9g)' },
      { value: '2U', label: '2U (90g - 95.9g)' },
      { value: 'U', label: 'U (> 95g)' }
    ]
  };

  filters.push(weightFilter);

  let gripsizeFilter = {
    id: 'gripsize',
    name: t('Grip sizes'),
    options: [
      { value: 'G4', label: t('G4 (Medium)') },
      { value: 'G5', label: t('G5 (Small)') },
      { value: 'G6', label: t('G6 (Very Small)') }
    ]
  };

  filters.push(gripsizeFilter);

  let materialFilter = {
    id: 'material',
    name: t('Material'),
    options: []
  };

  let materials = [];
  rackets.forEach(racket => {
    racket.materials.forEach(material => {
      if (!materials.includes(material)) {
        materialFilter.options.push({
          value: material,
          label: material
        });
        materials.push(material);
      }
    })
  });

  //filters.push(materialFilter);

  return (
    <div>
      {/* Mobile filter dialog */}
      <Dialog open={mobileFiltersOpen} onClose={setMobileFiltersOpen} className="relative z-40 lg:hidden">
        <div className="fixed inset-0 bg-black bg-opacity-25 transition-opacity" />
        <div className="fixed inset-0 z-40 flex">
          <DialogPanel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
            <div className="flex items-center justify-between px-4">
              <h2 className="text-lg font-medium text-gray-900">{t('Filters')}</h2>
              <button
                type="button"
                onClick={() => setMobileFiltersOpen(false)}
                className="relative -mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">{t('Close menu')}</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            {/* Filters */}
            <form className="mt-4">
              {filters.map((section) => (
                <Disclosure key={section.name} as="div" className="border-t border-gray-200 pb-4 pt-4">
                  <fieldset>
                    <legend className="w-full px-2">
                      <DisclosureButton className="group flex w-full items-center justify-between p-2 text-gray-400 hover:text-gray-500">
                        <span className="text-sm font-medium text-gray-900">{section.name}</span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            aria-hidden="true"
                            className="h-5 w-5 rotate-0 transform group-open:-rotate-180"
                          />
                        </span>
                      </DisclosureButton>
                    </legend>
                    <DisclosurePanel className="px-4 pb-2 pt-4">
                      <div className="space-y-6">
                        {section.options.map((option, optionIdx) => (
                          <div key={option.value} className="flex items-center">
                            <input
                              value={option.value}
                              id={`${section.id}-${optionIdx}-mobile`}
                              name={`${section.id}[]`}
                              type="checkbox"
                              onChange={(e) => handleFilterChange(e, section.id)}
                              checked={selectedFilters[section.id].includes(option.value)}
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor={`${section.id}-${optionIdx}-mobile`}
                              className="ml-3 text-sm text-gray-500"
                            >
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </DisclosurePanel>
                  </fieldset>
                </Disclosure>
              ))}
            </form>
          </DialogPanel>
        </div>
      </Dialog>

      <main className="mx-auto max-w-2xl px-4 lg:max-w-7xl lg:px-8">
        <div className="border-b border-gray-200 pb-10 pt-24">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">{t('Rackets')}</h1>
          <p className="mt-4 text-base text-gray-500">
            {t('Explore our comprehensive selection of badminton rackets available on the market today.')}
          </p>
        </div>
        <div className="mt-4">
            <input
              type="text"
              placeholder={t('Search for a racket')}
              value={searchQuery}
              onChange={handleSearch}
              className="block w-full px-4 py-2 border border-gray-300 rounded-md text-gray-900 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
        </div>

        <div className="pb-24 pt-12 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4">
          <aside>
            <h2 className="sr-only">{t('Filters')}</h2>

            <button
              type="button"
              onClick={() => setMobileFiltersOpen(true)}
              className="inline-flex items-center lg:hidden"
            >
              <span className="text-sm font-medium text-gray-700">{t('Filters')}</span>
              <PlusIcon aria-hidden="true" className="ml-1 h-5 w-5 flex-shrink-0 text-gray-400" />
            </button>

            <div className="hidden lg:block">
              <form className="space-y-10 divide-y divide-gray-200">
                {filters.map((section, sectionIdx) => (
                  <div key={section.name} className={sectionIdx === 0 ? null : 'pt-10'}>
                    <fieldset>
                      <legend className="block text-sm font-medium text-gray-900">{section.name}</legend>
                      <div className="space-y-3 pt-6">
                        {section.options.map((option, optionIdx) => (
                          <div key={option.value} className="flex items-center">
                            <input
                              value={option.value}
                              id={`${section.id}-${optionIdx}`}
                              name={`${section.id}[]`}
                              type="checkbox"
                              onChange={(e) => handleFilterChange(e, section.id)}
                              checked={selectedFilters[section.id].includes(option.value)}
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <label htmlFor={`${section.id}-${optionIdx}`} className="ml-3 text-sm text-gray-600">
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  </div>
                ))}
              </form>
            </div>
          </aside>

          <section aria-labelledby="product-heading" className="mt-6 lg:col-span-2 lg:mt-0 xl:col-span-3">
            <h2 id="product-heading" className="sr-only">
              {t('Rackets')}
            </h2>

            {/* Message if no rackets match */}
            {filteredRackets.length === 0 && (
              <div className="text-center text-gray-500">
                <p>{t('No rackets match the current criteria')}</p>
              </div>
            )}

            {/* Display filtered rackets */}
            <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8 xl:grid-cols-3">
              {visibleRackets.map((racket, index) => (
                <div
                  key={`${racket.uuid}-${index}`}
                  className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
                >
                  <div className="aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none group-hover:opacity-75 sm:h-96">
                    <img
                      src={racket.images && racket.images[0] ? racket.images[0] : '/images/logo.png'}
                      className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                      alt={racket.name}
                    />
                  </div>
                  <div className="flex flex-1 flex-col space-y-2 p-4">
                    <h3 className="text-sm font-medium text-gray-900">
                      <Link to={`/badminton/racket/${racket.uuid}`}>
                        <span aria-hidden="true" className="absolute inset-0" />
                        {racket.name || t('Name not available')}
                      </Link>
                    </h3>
                    <div className="flex flex-1 flex-col justify-end">
                      <p className="text-sm italic text-gray-500">{t('Brand')}: {racket.brand || t('Information not available')}</p>
                      <p className="text-sm italic text-gray-500">{t('Playing level')}: {t(racket.player_level) || t('Information not available')}</p>
                      <p className="text-sm italic text-gray-500">{t('Playing style')}: {t(`${racket.player_type}_SHORT`) || t('Information not available')}</p>
                      <p className="text-sm italic text-gray-500">{t('Balance')}: {t(racket.balance) || t('Information not available')}</p>
                      <p className="text-sm italic text-gray-500">{t('Flexibility')}: {t(racket.flexibility) || t('Information not available')}</p>
                      <p className="text-sm italic text-gray-500">{t('Weights')}: {racket.weights ? racket.weights.join(', ') : t('Information not available')}</p>
                      <IntlProvider locale="fr">
                        <p className="text-base font-medium text-gray-900">
                          {racket.lowestPrice ? (
                            <FormattedNumber value={racket.lowestPrice.amount} style="currency" currency={racket.lowestPrice.currency} />
                          ) : (
                            t('Price not available')
                          )}
                        </p>
                      </IntlProvider>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Bouton "Load More" */}
            {visibleRackets.length < filteredRackets.length && (
              <div className="mt-6 text-center">
                <button
                  onClick={loadMoreRackets}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {t('Load More')}
                </button>
              </div>
            )}

            {visibleRackets.length === filteredRackets.length && (
              <div className="text-center pt-6 text-gray-500">
                <p>{t('No more rackets to show')}</p>
              </div>
            )}
          </section>
        </div>
      </main>
    </div>
  );
}
