import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Home() {
  const { t } = useTranslation();

  useEffect(() => {
    localStorage.clear();
  });

  return (
    <div className="relative bg-white">
      <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
        <div className="px-6 pb-24 pt-10 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-56 lg:pt-48 xl:col-span-6">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <img
              alt="Racket Top"
              src="/images/logo.png"
              className="h-48"
            />
            <h1 className="mt-24 text-4xl font-bold tracking-tight text-gray-900 sm:mt-10 sm:text-6xl">
              {t('Find Your Perfect Racket')}
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
            {t('Explore a comprehensive directory of rackets from various brands and models. Whether you are looking for your next racket or simply exploring, we aim to list every racket available.')}
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <Link
                to="/badminton/racket-finder"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {t('Find my racket')}
              </Link>
              <a href="/badminton/rackets" className="text-sm font-semibold leading-6 text-gray-900">
              {t('Explore Rackets')} <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
        <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
          <img
            alt={t('Racket Player')}
            src="https://images.unsplash.com/photo-1721760886493-61c47c1caec9?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
          />
        </div>
      </div>
    </div>
  );
}
