import { useTranslation } from 'react-i18next';
import { FaFacebookF, FaInstagram, FaRedditAlien, FaEnvelope } from 'react-icons/fa';

const navigation = [
  {
    name: 'Mail',
    href: 'mailto:contact@rackets.top',
    icon: FaEnvelope,
  },
  {
    name: 'Facebook',
    href: 'https://www.facebook.com/profile.php?id=61567377357635',
    icon: FaFacebookF,
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/rackets.top',
    icon: FaInstagram,
  },
  {
    name: 'Reddit',
    href: 'https://www.reddit.com/user/racketstop',
    icon: FaRedditAlien,
  }
]

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          {navigation.map((item) => (
            <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">{t(item.name)}</span>
              <item.icon aria-hidden="true" className="h-6 w-6" /> {/* Utilisation de l'icône react-icons */}
            </a>
          ))}
        </div>
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center text-xs leading-5 text-gray-500">
            &copy; {t("2024 Rackets Top, Inc. All rights reserved.")}
          </p>
        </div>
      </div>
    </footer>
  )
};

export default Footer;
